@use 'themes' as *;

// -- AG GRID -- //
@use "ag-grid-community/styles" as ag;
@include ag.grid-styles((theme: balham,
    --ag-cell-horizontal-border: var(--ag-row-border-width) var(--ag-row-border-style) var(--ag-row-border-color),
    /* Add left and right borders to each cell and use row border's properties  */
    --ag-row-border-width:1px));

@import url('https://fonts.googleapis.com/css2?family=Cousine:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$secondary-font-family: 'Cousine', sans-serif;

@import "ngx-drag-to-select";

html {
  font-size: 13px;
}


// -- BODY & WINDOW -- //

body {
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;

}

.p-sidebar-right {
  top: calc(3.5rem + 41.3px) !important;
  bottom: 1.3px;
  height: auto;
  // overflow-y: auto;
  border-radius: 10px 0 0 10px;
}

.p-sidebar-mask {
  display: none;
}

.p-sidebar-content {
  padding: 0;
}

.dts-select-container:not(.dts-custom) .dts-select-item.selected {
  box-shadow: none;
  border: none;

  img {
    border: 3px solid #2196f3
  }
}

.dts-select-container:not(.dts-custom) .dts-select-item.dts-range-start {
  box-shadow: none;
  border: none;
}

input[type="file"] {
  display: none;
}

// .dts-select-box {
//   z-index: 400000000000 !important;
// }

.window-mode {
  background: white;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  display: block;
  text-align: left;
}

.secondary-font {
  font-family: $secondary-font-family;
}

// -- TAB MENU -- //
.experiment-navigation .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  padding: 0.25rem;
}

.p-menuitem-text {
  font-size: x-small;
  font-family: $secondary-font-family;
}

// -- INPUTS -- //
.thin-input {
  height: 2.2rem;

  &.p-inputgroup {
    .p-inputtext.p-inputtext-sm {
      font-family: $secondary-font-family;
    }
  }
}

.p-float-label {
  margin-top: 1.55rem;

  ~label {
    top: -0.5rem;
    font-size: 0.9rem;
  }
}

.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}


// .p-calendar .p-datepicker {
//   min-width: none;
//   top: 2.2rem;
// }

// -- Tooltip -- //
.p-tooltip .p-tooltip-text {
  font-family: $secondary-font-family;
  font-size: small;
}

// -- Buttons -- //
.p-button.p-button-tiny.p-button-icon-only {
  width: 2.2rem;
  height: 2.2rem;
  padding: 0.75rem 0;
}

.p-button.p-button-tiny .p-button-icon {
  font-size: 0.85rem;
}

.p-button.p-button-tiny {
  font-size: 0.85rem;
  padding: 0.45625rem 0.875rem;
}

.hidden-shadow {
  box-shadow: none !important;
}

// -- Menu -- // 

.p-menu .p-submenu-header {
  padding: 0.35rem 1.25rem;
  font-weight: 600;
  font-size: 0.8rem;
  color: #84878d;
}

// -- Panels -- //
.p-panel .p-panel-header {
  padding: 0.85rem 1.2rem;
}

.tight-panel {
  .p-overlaypanel .p-overlaypanel-content {
    padding: 1rem 0 0 0;
  }
}

// -- Tags -- //
.icon-only {
  .p-tag .p-tag-icon {
    margin: 1.2px 0 0 0;

  }
}

// -- Accodrions -- //
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0 1.25rem;
}

// -- Sign Up/In Classes -- //

.sign-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95vh;
  z-index: 5;
  position: relative;
}

.sign-card {
  border-radius: 12px;
  max-width: 400px;
  max-height: 88vh;

  .p-fieldset-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

/* Responsive layout - makes a two column-layout instead of four columns */
@media (max-width: 450px) {
  .sign-card {
    border-radius: 12px;
    width: 100%;
  }
}

.sign-spacer {
  opacity: 80%;
  margin: 8px
}

.sign-login-button {
  width: 90%;
  border-radius: 8px;
}


.sign-image {
  height: 24vh;
  padding-left: 80px;
  padding-right: 80px;
}

.full-width {
  width: 100%;

  .p-dropdown {
    width: 100%
  }

  .p-treeselect {
    width: 100%;
  }

  .p-multiselect {
    width: 100%;
  }

  .p-splitbutton {
    width: 100%;

    .p-tieredmenu.p-tieredmenu-overlay {
      width: 100%;
    }
  }
}

.p-calendar {
  width: 100%;
}

.vertical-only {
  resize: vertical;
}

.p-password {
  width: 100%;
}

.p-inputtext {
  width: 100%;
}

.p-overlaypanel {
  border: 1px solid var(--surface-200);
  box-shadow: 0 12px 12px 6px rgba(0, 0, 0, 0.05);

  .p-overlaypanel-content {
    padding-bottom: 0;
  }
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: var(--surface-200)
}


.full-width p-button,
.full-width button.pButton {
  width: 100%;
}

.p-chips {
  width: 100%;
}

.item-divider .p-divider.p-divider-horizontal {
  margin: 0;
}

.fixed-menu {
  .p-menu-overlay {
    position: fixed;
    display: block !important;
  }
}

.no-panel-padding {
  .p-panel .p-panel-content {
    padding: 0 1px;
  }
}


// -- p-divider -- //
.half-divider.p-divider-vertical {
  height: 50%;
  transform: translateY(50%);
}

.ag-root-wrapper {
  border-bottom: none;
  border-right: none;
  border-left: none;
}

.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
}

.color-picker {
  position: fixed !important;
  z-index: 10000 !important;
  /* Use an appropriate z-index value to ensure it appears on top */
}

.arrow {
  display: none;
}

// -- IMAGE UPLOAD --// 
.custom-file-upload {
  border-radius: 8px;
  background-color: var(--green-400);
  color: var(--text-secondary-color);
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  transition: transform .2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-file-upload:hover {
  transform: scale(1.04)
}

// -- TAB VIEW -- //
.transparent-tab {
  .p-tabview .p-tabview-panels {
    background: transparent;
  }

  .p-tabview .p-tabview-nav {
    background: transparent;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
  }
}


// -- Upload container -- //

.drag-overlay {
  position: absolute;
  top: 0;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  opacity: 0.96;
  backdrop-filter: blur(15px);
  --webkit-backdrop-filter: blur(15px);
}

.inner-border {
  border: 1px dashed #03a9f4;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  border-radius: 0.6rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}


.tag-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 6px;
}

.buffer-right {
  margin: 2px;
}